import Sidebar from '../components/Sidebar/Sidebar'
import Top from '../components/Top/Top'
import {useEffect, useState} from 'react'

import crypto from 'crypto-js'
import keccak from 'keccak'
import type {KeccakAlgorithm} from 'keccak'

import Selector from './Hash/Selector'

interface HasherHelper {
  (message: crypto.lib.WordArray | string, cfg?: object): crypto.lib.WordArray
}

const cryptoHasher = [
  // {name: 'HmacMD5', hasher: crypto.HmacMD5}
  {name: 'SHA1', hasher: crypto.SHA1},
  {name: 'SHA3', hasher: crypto.SHA3},
  {name: 'SHA224', hasher: crypto.SHA224},
  {name: 'SHA256', hasher: crypto.SHA256},
  {name: 'SHA384', hasher: crypto.SHA384},
  {name: 'SHA512', hasher: crypto.SHA512},
  {name: 'MD5', hasher: crypto.MD5},
  {name: 'RIPEMD160', hasher: crypto.RIPEMD160}
]

const keccakHasher = ['Keccak224', 'Keccak256', 'Keccak384', 'Keccak512']

const map: Map<string, HasherHelper> = new Map()
const menus: string[] = []
cryptoHasher.map(({name, hasher}) => {
  map.set(name, hasher)
  menus.push(name)
})

keccakHasher.map(name => {
  menus.push(name)
})

const Hash = () => {
  const [decoded, setDecoded] = useState<string>('')
  const [encoded, setEncoded] = useState<string>('')
  const [selected, setSelected] = useState<KeccakAlgorithm | string>('SHA256')

  const encode = (decoded: string) => {
    setDecoded(decoded)

    switch (selected) {
      case 'Keccak224':
      case 'Keccak256':
      case 'Keccak384':
      case 'Keccak512':
        const keccakSelected = selected.toLowerCase()
        setEncoded(
          keccak(keccakSelected as KeccakAlgorithm)
            .update(decoded)
            .digest()
            .toString('hex')
        )
        return
      default:
        const hasher = map.get(selected)
        if (hasher === undefined) {
          setEncoded('Error')
          return
        }
        setEncoded(hasher(decoded).toString())
    }
  }

  useEffect(() => {
    encode(decoded)
  }, [selected, setSelected])

  return (
    <div>
      <Sidebar />
      <Top />
      <div className='flex flex-col ml-64 mr-3 mt-3'>
        <Selector selected={selected} setSelected={setSelected} menus={menus} />
        <textarea
          value={decoded}
          onChange={e => encode(e.target.value)}
          id='encode'
          name='encode'
          rows={10}
          placeholder='plain text'
          className='border'
        ></textarea>
        <button
          className='m-1 btn btn-primary btn-xs'
          onClick={() => {
            encode(decoded)
          }}
        >
          Auto Hashing
        </button>
        <textarea
          value={encoded}
          onChange={e => setEncoded(encoded)}
          id='encode'
          name='encode'
          rows={10}
          className='border'
        ></textarea>
      </div>
      {/*<HashLink />*/}
    </div>
  )
}

export default Hash
