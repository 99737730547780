import {useStore} from './store'
import {Provider as ReduxProvider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import RoutesSetup from './routes/RoutesSetup'
import {Buffer} from 'buffer/'
import {useEffect} from 'react'
import FFmpegService from './components/ffmpeg'

window.Buffer = window.Buffer || Buffer
export default function App() {
  const store = useStore()
  useEffect(() => {
    FFmpegService.init()
  }, [])
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <RoutesSetup />
      </BrowserRouter>
    </ReduxProvider>
  )
}
