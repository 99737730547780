import {useMemo, useState} from 'react'

import FFmpegService from '../components/ffmpeg'
import {fetchFile} from '@ffmpeg/ffmpeg'

const useFFmpegConvert = (file: File | null) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [trimmedVideoFile, setTrimmedVideoFile] = useState<string | ArrayBuffer | null>(
    null
  )

  const convert = async () => {
    console.log('!!!!!!!!!!!!!!!!!!')
    if (file === null || isLoading) return
    setIsLoading(true)

    try {
      const newFileName = '2.' + file.name

      await FFmpegService.ffmpeg.FS('writeFile', file.name, await fetchFile(file))
      await FFmpegService.ffmpeg
        .run(
          '-ss',
          '00:00:05',
          '-i',
          file.name,
          '-t',
          '00:00:10',
          '-c:v',
          'copy',
          'output2.mp4'
        )
        .catch(error => {
          console.log('err', error)
        })
      console.log('???????????????????????????')
      const data = FFmpegService.ffmpeg.FS('readFile', 'output2.mp4')
      console.log('data', data)
      console.log('qqqqq')
      const dataURL: string | ArrayBuffer | null = await readFileAsBase64(
        new Blob([data.buffer], {type: 'video/mp4'})
      )
      console.log('dataURL', dataURL)
      setTrimmedVideoFile(dataURL)
    } catch (error) {
      console.log('error', error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }
  return {isLoading, convert, trimmedVideoFile}
}

const readFileAsBase64 = async (file: Blob) => {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export default useFFmpegConvert
