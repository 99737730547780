import React, {useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
// import {Upload} from 'react-feather'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

interface UploaderProps {
  onFileChange?(file: File): void
}

const Uploader = ({onFileChange}: UploaderProps) => {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {'video/mp4': ['.mp4']},
    onDrop: files => {
      console.log('qqq')
      console.log(acceptedFiles)

      onFileChange?.(files[0])
    }
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <div
      {...getRootProps(style)}
      className={
        'w-96 h-48 border-2 flex flex-col justify-center items-center border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
      }
    >
      <svg
        className='w-8 h-8 mb-4 text-gray-500 dark:text-gray-400'
        aria-hidden='true'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 20 16'
      >
        <path
          stroke='currentColor'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2'
          d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
        />
      </svg>
      <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
        <span className='font-semibold'>Click to upload</span> or drag and drop
      </p>
      <p className='text-xs text-gray-500 dark:text-gray-400'>
        MP4, AVI or Other Video (MAX. 4GB)
      </p>
      <input className='w-full border border-8  bg-amber-500' {...getInputProps()} />
      {/*<Upload />*/}
    </div>
  )
}

export default Uploader
