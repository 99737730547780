import {Link} from 'react-router-dom'

const Top = () => {
  return (
    <div className='flex flex-row-reverse bg-slate-200	 p-4 z-40 h-16'>
      <Link to='/'>Home</Link>
      {/*<Link to='/welcome' className='mr-4'>*/}
      {/*  Welcome*/}
      {/*</Link>*/}
      {/*<Link to='/board' className='mr-4'>*/}
      {/*  Board*/}
      {/*</Link>*/}
    </div>
  )
}

export default Top
