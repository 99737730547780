import {configureStore} from '@reduxjs/toolkit'
import {useMemo} from 'react'
import {rootReducer} from './rootReducer'
import logger from 'redux-logger'

const initializeStore = () => {
  const middleware: any[] = []
  middleware.push(logger)
  return configureStore({reducer: rootReducer, middleware: middleware})
}

export function useStore() {
  const store = useMemo(() => initializeStore(), [])
  return store
}
