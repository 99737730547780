import {Routes, Route} from 'react-router-dom'
import NoMatch from './NoMatch'
import Home from './Home'
import RLP from './RLP'
import Hash from './Hash'
import Encoder from './Encoder'
import Video from './Video'
export default function RoutesSetup() {
  return (
    <Routes>
      <Route path='/' element={<Hash />} />
      {/*<Route path='/rlp' element={<RLP />} />*/}
      <Route path='/hash' element={<Hash />} />
      <Route path='/video' element={<Video />} />
      {/*<Route path='/encoder' element={<Encoder />} />*/}
      {/*<Route path='/welcome' element={<RLP />} />*/}
      <Route path='*' element={<NoMatch />} />
    </Routes>
  )
}
