import Sidebar from '../components/Sidebar/Sidebar'
import Top from '../components/Top/Top'
import React, {useEffect, useState} from 'react'

import crypto from 'crypto-js'
import keccak from 'keccak'
import type {KeccakAlgorithm} from 'keccak'

import Selector from './Hash/Selector'
import Uploader from '../components/Uploader'
import Convert from '../components/Convert'
import ProgressBar from '../components/ProgressBar'

import useFFmpegConvert from '../hooks/useFFmpegConvert'

interface HasherHelper {
  (message: crypto.lib.WordArray | string, cfg?: object): crypto.lib.WordArray
}

const cryptoHasher = [
  // {name: 'HmacMD5', hasher: crypto.HmacMD5}
  {name: 'SHA1', hasher: crypto.SHA1},
  {name: 'SHA3', hasher: crypto.SHA3},
  {name: 'SHA224', hasher: crypto.SHA224},
  {name: 'SHA256', hasher: crypto.SHA256},
  {name: 'SHA384', hasher: crypto.SHA384},
  {name: 'SHA512', hasher: crypto.SHA512},
  {name: 'MD5', hasher: crypto.MD5},
  {name: 'RIPEMD160', hasher: crypto.RIPEMD160}
]

const keccakHasher = ['Keccak224', 'Keccak256', 'Keccak384', 'Keccak512']

const map: Map<string, HasherHelper> = new Map()
const menus: string[] = []
cryptoHasher.map(({name, hasher}) => {
  map.set(name, hasher)
  menus.push(name)
})

keccakHasher.map(name => {
  menus.push(name)
})

var fr = new FileReader()
const Video = () => {
  const [file, setFile] = useState<File | null>(null)

  const {convert, trimmedVideoFile} = useFFmpegConvert(file)

  const handleConvert = () => {
    convert()
  }

  console.log('file', file)
  return (
    <div className='flex flex-col'>
      <div>
        <Top />
      </div>
      <div className='flex flex-row'>
        <div>
          <Sidebar />
        </div>
        <div className='flex flex-col m-3 w-full border-2'>
          <div className='w-full flex justify-center'>
            {!file && <Uploader onFileChange={setFile} />}
            {file && (
              <Convert
                originalFile={file}
                onConvert={handleConvert}
                trimmedVideoFile={trimmedVideoFile}
              />
            )}
          </div>
        </div>
      </div>

      {/*<HashLink />*/}
    </div>
  )
}

export default Video
