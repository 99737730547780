import React from 'react'
import useBlobUrl from '../hooks/useBlobUrl'
import ProgressBar from './ProgressBar'

interface PreviousConvertStepProps {
  originalFile: File
  onConvert(): void
  trimmedVideoFile: string | ArrayBuffer | null
}

const Convert = ({
  originalFile,
  onConvert,
  trimmedVideoFile
}: PreviousConvertStepProps) => {
  const blobUrl = useBlobUrl(originalFile)
  console.log('previous')
  return (
    <div className='flex flex-col border border-2'>
      {blobUrl && <video className='mb-2' controls src={blobUrl} />}
      {!trimmedVideoFile && (
        <button
          className='bg-yellow-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded '
          onClick={onConvert}
        >
          Convert
        </button>
      )}
      {trimmedVideoFile && (
        <button
          className='bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded '
          onClick={() => {
            if (trimmedVideoFile === null) {
              return
            }
            download(trimmedVideoFile as string, new Date().toString())
          }}
        >
          Download
        </button>
      )}
    </div>
  )
}

const download = (url: string, fileName: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  link.click()
}

export default Convert
