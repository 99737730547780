import {
  Avatar,
  Drawer,
  List,
  Stack,
  Toolbar,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import Divider from '@mui/material/Divider'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import {useNavigate} from 'react-router-dom'

const drawerWidth = 180

type menu = {
  name: string
  nav: string
}

const menus: menu[] = [
  // {nav: '/rlp', name: '. RLP'},
  {nav: '/hash', name: '. HASH'},
  {nav: '/video', name: '. VIDEO'}
  // {nav: '/encoder', name: '. ENCODER/DECODER'}
]
const Sidebar = () => {
  const navigate = useNavigate()
  return (
    <Drawer
      sx={{
        // backgroundColor: 'rgba(200, 139, 195, 1.8)',
        // color: 'rgba(30, 139, 195, 0.8)',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant='permanent'
      anchor='left'
    >
      <Toolbar />
      <Divider />
      <List>
        {menus.map((menu, index) => (
          <ListItem key={menu.name} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(menu.nav)
              }}
            >
              <ListItemText primary={menu.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      {/*<List>*/}
      {/*    {['All mail', 'Trash', 'Spam'].map((text, index) => (*/}
      {/*        <ListItem key={text} disablePadding>*/}
      {/*            <ListItemButton>*/}
      {/*                <ListItemIcon>*/}
      {/*                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}*/}
      {/*                </ListItemIcon>*/}
      {/*                <ListItemText primary={text} />*/}
      {/*            </ListItemButton>*/}
      {/*        </ListItem>*/}
      {/*    ))}*/}
      {/*</List>*/}
    </Drawer>
  )
}

export default Sidebar
